import React, { useState } from "react";
import styled from "styled-components";
import Navbar from "./Navbar";
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';
import { FaArrowRight } from 'react-icons/fa';

const Section = styled.div`
    height : 100vh;
    scroll-snap-align: center;
`;

const Container = styled.div`
  width: 100%;
`;

const Navigation = styled.div`
  z-index: 1;
  width: 99%;
  position: absolute;
  left: 0;
  top: 0;
`;

const MainHeroSection = styled.div`
  height : 100vh;
  width: 100%;
  z-index: 0;
  display: flex;
  flex-direction: row;
`;

const HotelOne = styled.div`
  height : 100vh;
  width: 10%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./img/hero1.jpg);
  display: flex;
  /* transition: 0.5s ease-in-out;
  &:hover{
    transition: 0.5s ease-in-out;
    width: 80%;
  } */
`;
const HotelTwo = styled.div`
  height : 100vh;
  width: 10%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./img/hero2.jpg);
  display: flex;
  /* transition: 0.5s ease-out;
  &:hover{
    transition: 0.5s ease-out;
    width: 80%;
  } */
`;
const HotelThree = styled.div`
  height : 100vh;
  width: 10%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./img/hero3.jpg);
  display: flex;
  /* transition: 0.5s ease-out;
  &:hover{
    transition: 0.5s ease-out;
    width: 80%;
  } */
`;

const HotelFour = styled.div`
  height : 100vh;
  width: 70%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./img/hero5.jpg);
  display: flex;
  /* transition: 0.5s ease-out;
  &:hover{
    transition: 0.5s ease-out;
  } */
`;

const Left = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 35px;

`;
const Right = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`;

const LeftName = styled.div`
  font-family: 'Merriweather';
  letter-spacing: 1.5;
  padding: 10px;
  color: #f5e1cb;
  font-size: 1vw;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
const LeftIcon = styled.div`
  font-family: 'Merriweather';
  letter-spacing: 1.5;
  padding: 8px;
  color: #f5e1cb;
  font-size: 1vw;
  transition: 0.5s ease-out;
  animation: animateIcon 2s infinite ease alternate;
    @keyframes animateIcon {
      to {
        transform: translateX(10px);
      }
    }
  @media only screen and (max-width: 768px) {
    font-size: 3vw;
  }
`;

const ImgHotelLogos = styled.img`
  max-width: 300px;
  max-height: 300px;
  animation: animate 2s infinite ease alternate;
  @media only screen and (max-width: 768px) {
    max-width: 200px;
  }
  @keyframes animate {
    to {
      transform: translateY(20px);
    }
  }
`;

const H1 = styled.div`
  font-family: 'Caprasimo';
  letter-spacing: 2.5px;
  color: #fdfcf7;
  text-align: center;
  font-size: 1.9vw;
  animation: animate 2s infinite ease alternate;
  @media only screen and (max-width: 768px) {
    font-size: 5vw;
  }
  @keyframes animate {
    to {
      transform: translateY(20px);
    }
  }
`;
const H2 = styled.div`
  font-family: 'Permanent Marker';
  letter-spacing: 1.5px;
  color: #e9c960;
  text-align: center;
  font-size: 1.5vw;
  animation: animate 2s infinite ease alternate;
  @media only screen and (max-width: 768px) {
    font-size: 5vw;
  }
  @keyframes animate {
    to {
      transform: translateY(20px);
    }
  }
`;
const H3 = styled.div`
  font-family: 'Caprasimo';
  letter-spacing: 2.5px;
  color: #FFFCF2;
  text-align: center;
  font-size: 1.6vw;
  animation: animate 2s infinite ease alternate;
  @media only screen and (max-width: 768px) {
    font-size: 5vw;
  }
  @keyframes animate {
    to {
      transform: translateY(20px);
    }
  }
`;

const HotelSectionOne = () => {
  return (
        <Right>
          <Zoom bottom><H1>Randholee Resort & SPA</H1></Zoom>
          <Zoom top><ImgHotelLogos src="./img/Logo_Randholee.png" /></Zoom>
          
          <Fade top><H2>Life in the cloud line</H2></Fade>
          <Fade bottom><H3>Kandy</H3></Fade>
        </Right>
  );
};
const HotelSectionTwo = () => {
  return (
        <Right>
          <Zoom bottom><H1>The Firs</H1></Zoom>
          <Zoom top><ImgHotelLogos src="./img/the-firs-logo-white.png" /></Zoom>
          
          <Fade top><H2>Enjoy a life filled with legacy</H2></Fade>
          <Fade bottom><H3>Nuwara Eliya</H3></Fade>
        </Right>
  );
};
const HotelSectionThree = () => {
  return (
        <Right>
          <Zoom bottom><H1>Ellen's Place</H1></Zoom>
          <Zoom top><ImgHotelLogos src="./img/Logo_Ellens_Place.png" /></Zoom>
          
          <Fade top><H2>Revel the comfort in the capital</H2></Fade>
          <Fade bottom  ><H3>Colombo</H3></Fade>
        </Right>
  );
};
const HotelSectionFour = () => {
  return (
        <Right>
          <Zoom top><H1>ENJOY THE LUXURY OF</H1></Zoom>
          <Fade top><H2>THREE SEASONS</H2></Fade>
          <Zoom bottom><ImgHotelLogos src="./img/logo_white.png" /></Zoom>
        </Right>
  );
};



const Hero = () => {
  
  const [isShown, setIsShown] = useState(0);
  const [itemDelay, setItemDelay] = useState(false); 

  return (
    <Section>
      <Container>

        <Navigation>
          <Navbar/>
        </Navigation>

        <MainHeroSection>

        <HotelOne 
            onMouseEnter={() => {
              setIsShown(1);
              const timer = setTimeout(() => {
                setItemDelay(true);
              }, 500);
              return () => clearTimeout(timer);
            }}

            onMouseLeave={() =>{setIsShown(0);setItemDelay(false)}}

            style={{
              transition: '0.4s ease-out',
              width: isShown===1 ? '70%' : '10%',
            }}
        >           
          <Left><LeftName>Autumn</LeftName><LeftIcon><FaArrowRight /></LeftIcon></Left>
            {isShown===1 ? itemDelay ? <HotelSectionOne/>:null : null }
          </HotelOne>

        <HotelTwo 
            onMouseEnter={() => {
              setIsShown(2);
              const timer = setTimeout(() => {
                setItemDelay(true);
              }, 500);
              return () => clearTimeout(timer);
            }}

            onMouseLeave={() =>{setIsShown(0);setItemDelay(false)}}

            style={{
              transition: '0.4s ease-out',
              width: isShown===2 ? '70%' : '10%',
            }}
        >           
        <Left><LeftName>Spring</LeftName><LeftIcon><FaArrowRight /></LeftIcon></Left>
          {isShown===2 ? itemDelay ? <HotelSectionTwo/>:null : null }
        </HotelTwo>

        <HotelThree 
            onMouseEnter={() => {
              setIsShown(3);
              const timer = setTimeout(() => {
                setItemDelay(true);
              }, 500);
              return () => clearTimeout(timer);
            }}

            onMouseLeave={() =>{setIsShown(0);setItemDelay(false)}}

            style={{
              transition: '0.4s ease-out',
              width: isShown===3 ? '70%' : '10%',
            }}
        >           
        <Left><LeftName>Winter</LeftName><LeftIcon><FaArrowRight /></LeftIcon></Left>
          {isShown===3 ? itemDelay ? <HotelSectionThree/>:null : null }
        </HotelThree>

        <HotelFour  
            onMouseEnter={() => {
              setIsShown(0);
              const timer = setTimeout(() => {
                setItemDelay(true);
              }, 500);
              return () => clearTimeout(timer);
            }}

            onMouseLeave={() =>{setIsShown(0);setItemDelay(true)}}

            style={{
              transition: '0.4s ease-out',
              width: isShown===0 ? '70%' : '10%',
            }}
        >
           {isShown===0 ? itemDelay ? <HotelSectionFour/>:null : null }
        </HotelFour>

        </MainHeroSection>
        
      </Container>
    </Section>
  );
};

export default Hero;
import React, {useRef, useEffect, useState } from 'react'
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

const Section = styled.div`
  height : 100vh;
  width: 100%;
  scroll-snap-align: center;
  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./img/hotel-bg.png'); */
`
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  margin-right: 50px;
  padding: 5px;
  @media only screen and (max-width: 768px) {
    margin-left: 30px;
    margin-right: 30px;
    padding: 15px;
  }
`
const Caption = styled.div`
  font-family: 'Merriweather';
  letter-spacing: 1.5;
  font-size: 1.5vw;
  color: antiquewhite;
  padding-top: 15px;
  padding-bottom: 1px;
  @media only screen and (max-width: 768px) {
    font-size: 3vw;
  }
`
const Description = styled.div`
  font-size: 0.8vw;
  color: #bba588;
  margin-top: 5px;
  @media only screen and (max-width: 768px) {
    font-size: 3vw;
  }
`
const HeaderSection = styled.div`
    margin-top: 175px;
    @media only screen and (max-width: 768px) {
      margin-top: 75px;
    }
`

const BoxOne = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: center; */
  /* background: linear-gradient(to bottom,  rgb(99, 74, 41), rgba(121, 95, 68, 0.596),  rgba(190, 157, 107, 0.596)); */
  /* box-shadow: '1px 2px 9px #804a0c'; 
  padding: 5px; */
  margin-top: 25px;
`
const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  margin: 10px;
  @media only screen and (max-width: 768px) {
    /* width: 100%; */
  }
`


const HotelRoomImg = styled.img`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  object-fit: cover;
  width: 100%;
  height: 30vh;
  max-width: 300px;
  min-width: 300px;
  border-radius: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  border: 2px solid #311f03;
  transition: 0.5s ease-out;
  &:hover{
    transition: 0.5s ease-out;
    opacity: 0.6;
    border: 2px solid #c78212;
    transform: translateY(3px);
  }

  /* &:hover{
    animation: roomAnimation 500ms ease-out alternate;
    @keyframes roomAnimation {

      0% {box-shadow: 0px 0px 0px 1px #302d28;
        opacity: 1}
      70% {box-shadow: 0px 0px 0px 3px #c79a07;
        opacity: 0.5}
      100% {box-shadow: 0px 0px 0px 1px #9e7f44;
        opacity: 1}
    }
  } */

  @media only screen and (max-width: 768px) {
    height: 10vh;
  }

  @media only screen and (max-width: 900px) {
    height: 10vh;
  }
`

const RoomCaption = styled.div`
  
  font-family: 'Merriweather';
  letter-spacing: 1.5;
  font-size: 2vw;
  color: antiquewhite;
  max-width: 300px;
  margin-top: 10px;
  @media only screen and (max-width: 768px) {
    font-size: 3vw;
    max-width: 250px;
  }
`
const RoomDescription = styled.div`
  font-size: 1vw;
  color: antiquewhite;
  margin-top: 5px;
  max-width: 300px;
  @media only screen and (max-width: 768px) {
    font-size: 2vw;
    max-width: 250px;
  }
`

const BookingSections = (props) => {
  const hotelRef = useRef();
  const [myElementIsVisible, updateMyElementIsVisible] = useState();

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      updateMyElementIsVisible(entry.isIntersecting);
    });
    observer.observe(hotelRef.current);
  }, []);

  return(
    <HeaderSection>
      <div ref={hotelRef}></div>
      { myElementIsVisible ?
                          <Fade top>
                          <Card>
                          <Caption>FREUDENBERG LEISURE</Caption>
                          <Description>Upscale Small Hotel Experiences</Description>

                          <BookingForm/>
                          
                          </Card>
                          </Fade>
              : <Caption></Caption> }
    </HeaderSection>
  );
}


const BookingForm = () => {
  return(
    <>
        <Fade top>
        <BoxOne>
        
        <FormItem>
        <HotelRoomImg src="./img/hotel1.jpg"/>
        <RoomCaption>RANDHOLEE RESORT AND SPA</RoomCaption>
        <RoomDescription>A upscale hotel built atop a misty and magical mountain</RoomDescription>

        </FormItem>

        <FormItem>
        <HotelRoomImg src="./img/hotel2.jpg"/>
        <RoomCaption>ELLEN'S PLACE</RoomCaption>
        <RoomDescription>Set against an urban landscape of concrete, steel and glass, Ellens’ Place stands out with a unique silhouette and a lush green garden</RoomDescription>
 
        </FormItem>

        <FormItem>
        <HotelRoomImg src="./img/hotel3.jpg"/>
        <RoomCaption>THE FIRS</RoomCaption>
        <RoomDescription>Elegant and cozy, this exquisite heritage bungalow that hearkens back to the British colonial</RoomDescription>
        </FormItem>
        
        </BoxOne>
        </Fade>
    </>
  );
}

export default function booking() {

  return (
    <Section>
      <Container>

        <BookingSections/>

      </Container>
    </Section>
  )
}

import React, {useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';

const Section = styled.div`
  height : 100vh;
  width: 100%;
  scroll-snap-align: center;
`
const Container = styled.div`
  width: 100%;
  display: flex;
`
const Box = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: row;
  margin-top: 175px;
  @media only screen and (max-width: 768px) {
    margin-top: 110px;
    flex-direction: column;
  }
`
const Left = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`
const Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Right = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`
const LeftHotelImage = styled.img`
  display: flex;
  object-fit: cover;
  width: 100%;
  height: 80vh;
  border-radius: 15px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const LeftSide = () => {
  const leftRef = useRef();
  const [myElementIsVisible, updateMyElementIsVisible] = useState();

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      updateMyElementIsVisible(entry.isIntersecting);
    });
    observer.observe(leftRef.current);
  }, []);

  return(
    <>
      <div ref={leftRef}></div>
      { myElementIsVisible ?
        <Fade top>
          <LeftHotelImage src="./img/hotelLeft.jpg"/>
        </Fade>
      : <div>...</div> }
    </>
  )
}

const StatisticBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`
const StatisticBoxItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2px;
  padding: 20px;
  background: linear-gradient(to bottom,  rgb(99, 74, 41), rgba(121, 95, 68, 0.596),  rgba(190, 157, 107, 0.596));
  cursor: pointer;
    
  transition: 0.5s ease-in-out;
  &:hover{
    transition: 0.5s ease-in-out;
    opacity: 0.6;
  }
`
const StatisticBoxTitle = styled.div`
  font-family: 'Merriweather';
  text-align: center;
  letter-spacing: 1.5px;
  font-size: 1.5vw;
  color: #e2ceb3;
  
  transition: 0.5s ease-in-out;
  &:hover{
    transition: 0.5s ease-in-out;
    color: #ffffff;
  }
`
const StatisticBoxNum = styled.div`
  font-family: 'Merriweather';
  text-align: center;
  letter-spacing: 1.5px;
  font-size: 1vw;
  color: antiquewhite;
`
const CenterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end; 
`
const CenterTitleText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; 
  font-family: 'Permanent Marker';
  text-align: center;
  letter-spacing: 1.5px;
  /* font-size: 1.3vw; */
  color: antiquewhite;
  margin-bottom: 20px;
`

const CenterSide = () => {
  const centerRef = useRef();
  const [myElementIsVisible, updateMyElementIsVisible] = useState();

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      updateMyElementIsVisible(entry.isIntersecting);
    });
    observer.observe(centerRef.current);
  }, []);

  return(
    <>
      <div ref={centerRef}></div>
      { myElementIsVisible ?
        <Fade top>
        
        <CenterContent>

        <CenterTitleText>
          We Provide Hotel With Top-Notch Amenities.
        </CenterTitleText>

            <StatisticBox>
              <StatisticBoxItem>
                <StatisticBoxTitle>STAFFS</StatisticBoxTitle>
                <StatisticBoxNum>49+</StatisticBoxNum>
              </StatisticBoxItem>
              <StatisticBoxItem>
                <StatisticBoxTitle>REVIEWS</StatisticBoxTitle>
                <StatisticBoxNum>2.5m</StatisticBoxNum>
              </StatisticBoxItem>
            </StatisticBox>
            <StatisticBox>
              <StatisticBoxItem>
                <StatisticBoxTitle>BOOKINGS</StatisticBoxTitle>
                <StatisticBoxNum>62k</StatisticBoxNum>
              </StatisticBoxItem>
              <StatisticBoxItem>
                <StatisticBoxTitle>CLIENTS</StatisticBoxTitle>
                <StatisticBoxNum>660+</StatisticBoxNum>
              </StatisticBoxItem>
            </StatisticBox>

            </CenterContent>

        </Fade>
      : <div>...</div> }
    </>
  )
}

const RightSideContent = styled.div`
  display: flex;
  text-align: justify;
  font-family: 'Merriweather';
  letter-spacing: 1.5px;
  color: #e2ceb3;
  @media only screen and (max-width: 768px) {
    margin-top: 20px;
    font-size: 3vw;
    padding: 10px;
  }
`
const TestimonialImg = styled.img`
  display: flex;
  width: 100%;
  max-width: 209px;

  @media only screen and (max-width: 768px) {
    margin-top: 20px;
    max-width: 150px;
    padding: 10px;
  }
`
const HotelRoomImg = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  max-width: 400px;
  cursor: pointer;
  border: 2px solid #311f03;
  transition: 0.5s ease-out;
  &:hover{
    transition: 0.5s ease-out;
    opacity: 0.6;
    border: 2px solid #c78212;
    transform: translateY(3px);
  }

  @media only screen and (max-width: 768px) {
    max-width: 350px;
  }
`
const ClientStatText = styled.div`
  display: flex;
  text-align: justify;
  font-family: 'Merriweather';
  letter-spacing: 1.5px;
  color: #e2ceb3;
`

const RightSide = () => {
  const rightRef = useRef();
  const [myElementIsVisible, updateMyElementIsVisible] = useState();

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      updateMyElementIsVisible(entry.isIntersecting);
    });
    observer.observe(rightRef.current);
  }, []);

  return(
    <>
      <div ref={rightRef}></div>
      { myElementIsVisible ?
        <Fade top>
          
          <RightSideContent>
          We offer delightful accommodation facilities in Colombo, Kandy and Nuwara Eliya. Contact us to arrange a round-trip package  if you want to stay in one or more cities during  your visit to Sri Lanka. Enjoy the rooms and suites at Ellen’s Place in Colombo, at the Randholee Resort or suites at Firs.
          </RightSideContent>

          <TestimonialImg src="./img/testi.png"/>
          <ClientStatText>4.8 - Global Rating</ClientStatText>
          <HotelRoomImg src="./img/hotel1.jpg"/>


        </Fade>
      : <div>...</div> }
    </>
  )
}

export default function luxury() {
  return (
    <Section>
      <Container>
      
        <Box>
          <Left>
            <LeftSide/>
          </Left>
          <Center>
            <CenterSide/>
          </Center>
          <Right>
            <RightSide/>
          </Right>
        </Box>
   
      </Container>
    </Section>
  )
}

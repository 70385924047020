import React from 'react'
import styled from 'styled-components';
import Zoom from 'react-reveal/Zoom';
import Fade from 'react-reveal/Fade';

const Section = styled.div`
  height : 100vh;
  width: 100%;
  scroll-snap-align: center;
  display: flex;
`
const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`
const MainTopic = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 170px;
`
const LinkSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 30px;

`
const MenuSections = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; */
  /* width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2px; */
`
const MainCaption = styled.div`
  width: 100%;
  text-align: center;
  font-family: 'Merriweather';
  letter-spacing: 1.5px;
  color: antiquewhite;
`
const SubContact = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Merriweather';
  letter-spacing: 1.5px;
  color: #dbc7a1;
  padding-top:2px;
`
const Line = styled.div`
  width: 100%;
  height: 2px;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #473d283b;
  margin-bottom: 10px;
`
const CopyWrite = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Merriweather';
  letter-spacing: 1.5px;
  color: #dbc7a1;
  padding-top:8px;
`
const SiteLogo = styled.img`
    width: 300px;
    height: 200px;
`

export default function Footer() {
    return (
      <Section>
        <Container>

        <Fade top><MainTopic><SiteLogo src="./img/logo_white.png"/></MainTopic></Fade>     

           <Line/>

           <Fade left>

           <LinkSection>
           
            <MenuSections>
                <MainCaption>The Firs</MainCaption>
                <SubContact>The Firs 85/2</SubContact>
                <SubContact>Upper Lake Road</SubContact>
                <SubContact>Nuwara Eliya</SubContact>
                <SubContact>Sri Lanka.</SubContact>
                <SubContact>Tel.: +94 522 222 387</SubContact>
                <SubContact>Fax: +94 522051687</SubContact>
                <SubContact>E-mail: info@firs.lk</SubContact>
            </MenuSections>
             
            <MenuSections>
                <MainCaption>Ellen's Place</MainCaption>
                <SubContact>31,</SubContact>
                <SubContact>Shady grove avenue,</SubContact>
                <SubContact>Colombo 08,</SubContact>
                <SubContact>Sri Lanka.</SubContact>
                <SubContact>Tel.: +94 112 680 062</SubContact>
                <SubContact>Fax: +94 112 860 063</SubContact>
                <SubContact>E-mail: info@ellensplace.lk</SubContact>
            </MenuSections>

            <MenuSections>
                <MainCaption>Randholee Resorts & Spa</MainCaption>
                <SubContact>Heerassagala Rd,</SubContact>
                <SubContact>Bowalawatte,</SubContact>
                <SubContact>Kandy,</SubContact>
                <SubContact>Sri Lanka.</SubContact>
                <SubContact>Tel.: + 94 81 2217741 – 3</SubContact>
                <SubContact>Fax: + 94 81 2217744</SubContact>
                <SubContact>E-mail: reservations@randholeeresorts.com</SubContact>
            </MenuSections>
           
           </LinkSection>  

           </Fade> 

           <Line/>

           <Fade bottom>
           <LinkSection>
           
            <MenuSections>
                <MainCaption>CONTACT</MainCaption>
                <SubContact>Reservation</SubContact>
                <SubContact>Mission & Vision</SubContact>
                <SubContact>Hotel Facilities</SubContact>
                <SubContact>News & Blogs</SubContact>
                <SubContact>Terms of Use</SubContact>
                <SubContact>Personal Information</SubContact>
                <SubContact>Data Tracking</SubContact>
            </MenuSections>

            <MenuSections>
                <MainCaption>HELP</MainCaption>
                <SubContact>Careers</SubContact>
                <SubContact>Cookie Policy</SubContact>
                <SubContact>Website Feedback</SubContact>
                <SubContact>Service Guarantee</SubContact>
            </MenuSections>

            <MenuSections>
                <MainCaption>OUR SERVICES</MainCaption>
                <SubContact>Store Directory</SubContact>
                <SubContact>Top Hotels</SubContact>
                <SubContact>Quick Links</SubContact>
                <SubContact>Important Links</SubContact>
                <SubContact>Insights</SubContact>
                <SubContact>Knowledge Center</SubContact>
            </MenuSections>

            <MenuSections>
                <MainCaption>ABOUT US</MainCaption>
                <SubContact>Company</SubContact>
                <SubContact>Partners</SubContact>
                <SubContact>Customers</SubContact>
                <SubContact>Pricing</SubContact>
            </MenuSections>

            <MenuSections>
                <MainCaption>EVENT</MainCaption>
                <SubContact>Investor Relations</SubContact>
                <SubContact>Management</SubContact>
                <SubContact>Site Review</SubContact>
            </MenuSections>
           
           </LinkSection>
           </Fade>

           <Line/>

           <Zoom top>
           <CopyWrite>© 2023 Freudenberg Leisure. Developed by ICTechno</CopyWrite>
           </Zoom>
  
        </Container>
      </Section>
    )
  }
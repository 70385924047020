import React from 'react'
import styled from 'styled-components';
import { OrbitControls, Stage } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import Chair from './Chair';

const Section = styled.div`
  width: 100%;
  height : 100vh;
  scroll-snap-align: center;
  display: flex;
`
const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`
const Box = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 170px;
  margin-bottom: 50px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 120px;
    margin-bottom: 10px;
  }
`
const Right = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #665a5186;
  border-radius: 10px;
  margin-right: 20px;
  @media only screen and (max-width: 768px) {
    margin: 0px;
    height: 50%;
    width: 100%;
  }
`
const Left = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #3d2f238b;
  border-radius: 10px;
  margin-left: 20px;
  @media only screen and (max-width: 768px) {
    margin: 0px;
  }
`
const SubscribeText = styled.div`
  display: flex;
  font-family: 'Merriweather';
  letter-spacing: 1.5px;
  color: #e2ceb3;
  font-size: 3.5vw; 
  margin-bottom: 10px;
  margin-top: 15px;
  @media only screen and (max-width: 768px) {
    font-size: 8vw; 
  }
`
const SubscribeSubText = styled.div`
  display: flex;
  font-family: 'Permanent Marker';
  letter-spacing: 1.5px;
  color: #e2ceb3;
  font-size: 1.8vw;
  margin-bottom: 50px;
  @media only screen and (max-width: 768px) {
    font-size: 8vw; 
  }
`
const Button = styled.button`
  display: flex;
  background-color: #442905;
  border: none;
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
  font-family: 'Merriweather';
  letter-spacing: 1.5px;
  color: #e2ceb3;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 2px solid #311f03;
  transition: 0.5s ease-out;
  &:hover{
    transition: 0.5s ease-out;
    opacity: 0.6;
    border: 2px solid #8a5e18;
    transform: translateY(3px);
  }
`

export default function Subscribe() {
    return (
      <Section>
        <Container>

        <Box>
          <Left>
          <SubscribeText>SUBSCRIBE</SubscribeText>
          <SubscribeSubText>For More Deals</SubscribeSubText>

          <input style={{width:'350px'}} type="email" placeholder="youremail@mail.com"  className='inputBooking' />
          <Button>Submit</Button>
          </Left>
          
          <Right>
            <Canvas>
              <OrbitControls enableZoom={false} autoRotate/>
              <Stage environment="city" intensity={0.6}>
                <Chair/>
              </Stage>
            </Canvas>
          </Right>
        </Box>



  
        </Container>
      </Section>
    )
  }
/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.5 chair.gltf --transform
Author: soidev (https://sketchfab.com/soidev)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/dining-chair-05e815d0f2f64647b646e9b71f8ec9ed
Title: Dining Chair
*/

import React, {  } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/chair-transformed.glb')
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.DiningxStool_DiningChair_0.geometry} material={materials.DiningChair} position={[-0.545, 0, -0.71]} rotation={[-Math.PI / 2, 0, 0.203]} scale={1.029} />
    </group>
  )
}

useGLTF.preload('/chair-transformed.glb')

import React, {useRef, useEffect, useState } from 'react'
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

const Section = styled.div`
  height : 100vh;
  width: 100%;
  scroll-snap-align: center;
  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./img/booking-bg.png'); */
`
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  margin-right: 50px;
  padding: 5px;
  @media only screen and (max-width: 768px) {
    margin-left: 30px;
    margin-right: 30px;
    padding: 15px;
  }
`
const Caption = styled.div`
  font-family: 'Merriweather';
  letter-spacing: 1.5;
  font-size: 1.5vw;
  color: antiquewhite;
  padding-top: 15px;
  padding-bottom: 1px;
  @media only screen and (max-width: 768px) {
    font-size: 3vw;
  }
`
const Description = styled.div`
  font-size: 0.8vw;
  color: #bba588;
  margin-top: 5px;
  @media only screen and (max-width: 768px) {
    font-size: 3vw;
  }
`
const HeaderSection = styled.div`
  margin-top: 175px;
  @media only screen and (max-width: 768px) {
    margin-top: 75px;
  }
`

const BoxOne = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to bottom,  rgb(99, 74, 41), rgba(121, 95, 68, 0.596),  rgba(190, 157, 107, 0.596));
  box-shadow: '1px 2px 9px #804a0c';
  border-radius: 15px;
  padding: 5px;
  margin-top: 25px;
`
const FormItem = styled.div`
  display: flex;
  margin: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    /* width: 100%; */
  }
`
const Captions = styled.div`
  display: flex;
  font-family: 'Merriweather';
  font-size: 0.8vw;
  letter-spacing: 1.5px;
  color: antiquewhite;
  padding-right: 10px;
  @media only screen and (max-width: 768px) {
    font-size: 1.5vw;
  }
`

const Controllers = styled.div`
  display: flex;
  font-family: 'Merriweather';
  letter-spacing: 1.5px;
  font-size: 1vw;
  color: antiquewhite;
  margin-top: 5px;
  @media only screen and (max-width: 768px) {
    font-size: 1.5vw;
  }
`

const HotelHeaderSection = styled.div`
    margin-top: 10px;
`

const HotelImageBox = styled.div`
    display: flex;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 50px;
    align-items:center;
    justify-content: center;
    @media only screen and (max-width: 768px) {

    }
`

const MainImageFragment = styled.div`
    display: flex;
    flex-direction: column;
`
const ImgContent = styled.div`
    overflow: hidden;
    object-fit: fill;
    border-radius: 10px;
    margin:5px;
`
const HotelRoomImg = styled.img`
  display: flex;
  align-items:center;
  justify-content: space-between;
  object-fit: cover;
  width: 100%;
  height: 30vh;
  max-width: 320px;
  border-radius: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    height: 20vh;
  }

  border: 2px solid #311f03;
  transition: 0.5s ease-out;
  &:hover{
    transition: 0.5s ease-out;
    opacity: 0.6;
    border: 2px solid #c78212;
    transform: translateY(3px);
  }
`

const RoomCaption = styled.div`
  font-family: 'Merriweather';
  letter-spacing: 1.5;
  font-size: 2vw;
  color: antiquewhite;
  @media only screen and (max-width: 768px) {
    font-size: 3vw;
  }
`
const RoomDescription = styled.div`
  font-size: 1vw;
  color: antiquewhite;
  margin-top: 5px;
  @media only screen and (max-width: 768px) {
    font-size: 2vw;
  }
`
const Button = styled.button`
  display: flex;
  background-color: #442905;
  border: none;
  padding: 15px;
  cursor: pointer;
  border-radius: 10px;
  font-family: 'Merriweather';
  letter-spacing: 1.5px;
  color: #e2ceb3;
  margin-top: 10px;
  border: 2px solid #795d31;
  transition: 0.5s ease-out;
  &:hover{
    transition: 0.5s ease-out;
    opacity: 0.6;
    border: 2px solid #8a5e18;
    transform: translateY(3px);
  }
`

const BookingSections = (props) => {
  const myRef = useRef();
  const [myElementIsVisible, updateMyElementIsVisible] = useState();

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      updateMyElementIsVisible(entry.isIntersecting);
    });
    observer.observe(myRef.current);
  }, []);

  return(
    <HeaderSection>
      <div ref={myRef}></div>
      { myElementIsVisible ?
                          <Fade top>
                          <Card>
                          <Caption>HOTEL BOOKING</Caption>
                          <Description>Upscale Small Hotel Experiences</Description>

                          <BookingForm/>
                          
                          </Card>
                          </Fade>
              : <Caption></Caption> }
    </HeaderSection>
  );
}


const BookingForm = () => {
  return(
    <>
        <Fade top>
        <BoxOne>
        
        <FormItem>
        <Captions>Select the Hotel</Captions>
        <Controllers> 
          <select  className='inputBooking'>
            <option>Randholee Resort & SPA</option>
            <option>The Firs</option>
            <option>Ellen's Place</option>
          </select>
        </Controllers>
        </FormItem>

        <FormItem>
        <Captions>Check-in</Captions>
        <Controllers> 
          <input type="date" placeholder="Check-in" className='inputBooking' />
        </Controllers>
        </FormItem>

        <FormItem>
        <Captions>Check-out</Captions>
        <Controllers> 
          <input type="date" placeholder="Check-out"  className='inputBooking' />
        </Controllers>
        </FormItem>

        <FormItem>
        <Captions>Adults</Captions>
        <Controllers>
          <input style={{width:'80px'}} type="number" placeholder="1"  className='inputBooking' />
        </Controllers>
        </FormItem>

        <FormItem>
        <Captions>Children</Captions>
        <Controllers> 
          <input style={{width:'80px'}} type="number" placeholder="1"  className='inputBooking' />
        </Controllers>
        </FormItem>

        <FormItem>
        <Controllers> 
          <Button>Search</Button>
        </Controllers>
        </FormItem>
        
        </BoxOne>
        </Fade>
    </>
  );
}


const HotelSectionImages = () => {
  return(
    <HotelImageBox>

      <MainImageFragment>
        <ImgContent><HotelRoomImg src="./img/room1.jpg"/></ImgContent>
        <RoomDescription>1 QUEEN SIZE BED - 2 GUEST</RoomDescription>
        <RoomCaption>QUEEN ROOM</RoomCaption>
      </MainImageFragment>
      
      <MainImageFragment>
        <ImgContent><HotelRoomImg src="./img/room2.jpg"/></ImgContent>
        <RoomDescription>1 QUEEN SIZE BED - 2 GUEST</RoomDescription>
        <RoomCaption>QUEEN ROOM</RoomCaption>
      </MainImageFragment>

      <MainImageFragment>
        <ImgContent><HotelRoomImg src="./img/room3.jpg"/></ImgContent>
        <RoomDescription>1 QUEEN SIZE BED - 2 GUEST</RoomDescription>
        <RoomCaption>QUEEN ROOM</RoomCaption>
      </MainImageFragment>

    </HotelImageBox>
  );
}


const BookingHotels = () => {
  const bookingRef = useRef();
  const [myElementIsVisible, updateMyElementIsVisible] = useState();

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      updateMyElementIsVisible(entry.isIntersecting);
    });
    observer.observe(bookingRef.current);
  }, []);

  return(
    <HotelHeaderSection>
      <div ref={bookingRef}></div>
      { myElementIsVisible ?
        <Zoom top>
          <HotelSectionImages/>
        </Zoom>
        : <div>...</div> }
    </HotelHeaderSection>
  );
}

export default function Booking() {

  const [bookingDelay, setBookingDelay] = useState(false); 
  const [hotelDelay, setHotelDelay] = useState(false); 

  useEffect(() => {
    const timer = setTimeout(() => {
      setBookingDelay(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHotelDelay(true);
    }, 2500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Section>
      <Container>


      {bookingDelay ?
          <BookingSections/>
          : <></> }

      {hotelDelay ?
          <BookingHotels/>
          : <></> }
        
      </Container>
    </Section>
  )
}

import React from 'react'
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import { FaSearch, FaStream } from 'react-icons/fa';
import { useEffect, useState } from 'react';

const Section = styled.div`
  width: 100%;
  display: flex;
  scroll-snap-align: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Links = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to bottom,  rgb(36, 24, 8), rgba(58, 39, 10, 0.39),  rgba(255,0,0,0));
  background-size: 100%;
  flex-wrap: wrap;
  @media only screen and (max-width: 768px) {
    /* height: 100px; */
    flex-wrap: nowrap;
  }
  @media only screen and (max-width: 900px) {
    flex-wrap: nowrap;
  }
`;

const Logo = styled.img`
  height: 150px;
  @media only screen and (max-width: 768px) {
    height: 100px;
  }
`;

const List = styled.ul`
  display: flex;
  gap: 20px;
  list-style: none;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const ListItem = styled.li`
  cursor: pointer;
  color: white;
  font-family: 'Segoe UI';
  letter-spacing: 1px;
  font-size: 0.8vw;
  padding: 5px;
  @media only screen and (max-width: 768px) {
    font-size: 1.8vw;
  }
  &:hover{
    transition: 0.4s ease-out;
    color:#e8ce68;
    background-color: #463002;
    border-radius: 5px;
    /* transform: translateX(5px); */
  }
`;

const Icons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-right: 20px;
  color: white;
`;

const MobileMenu = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: inline;
  }
`;



export default function Navbar() {

  const [logoDelay, setLogoDelay] = useState(false); 
  const [menuDelay, setMenuDelay] = useState(false); 

  useEffect(() => {
    const timer = setTimeout(() => {
      setLogoDelay(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMenuDelay(true);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);


  return (
    <Section>
      <Container>
        <Links>
          
          {logoDelay ?
          <Fade top><Logo src="./img/logo_white.png" /></Fade>
          : <></> }
          
          <List> 
          {menuDelay ?
          <>
            <Fade left>
              <ListItem>HOME</ListItem>
              <ListItem>HOTELS</ListItem>
              <ListItem>EXPERIENCES</ListItem>
              <ListItem>SPECIAL OFFERS</ListItem>
              <ListItem>ACCOMMODATION</ListItem>
              <ListItem>ABOUT US</ListItem>
              <ListItem>CONTACT US</ListItem>
            </Fade>
          </>
          : <></> }
          </List>
          
          <Icons>
          {menuDelay ?
          
            <>
            <Fade left><FaSearch /><MobileMenu><FaStream/></MobileMenu></Fade>
            </>

          : <></> }
          </Icons>

        </Links>
      </Container>
    </Section>
  )
}

import styled from 'styled-components';
import Hero from './Components/hero';
import Booking from './Components/booking';
import Hotels from './Components/hotels';
import Luxury from './Components/luxury';
import Footer from './Components/footer';
import Subscribe from './Components/subscribe';

const Container = styled.div`
  height : 100vh;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: auto;
  scrollbar-width: none;
  scroll-snap-align: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./img/booking-bg.png');
  &::-webkit-scrollbar{
    /* display: none; */
  }
`

function App() {
  return (
    <Container>
      <Hero></Hero>
      <Booking></Booking>
      <Hotels></Hotels>
      <Luxury></Luxury>
      <Subscribe></Subscribe>
      <Footer></Footer>
    </Container>
  );
}

export default App;
